import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Cell,
} from 'recharts';
import { Select, MenuItem, FormControl } from '@mui/material';
import {
  RANGE_ALL_TIME,
  RANGE_MONTHLY,
  RANGE_NIGHENTY_DAYS,
  RANGE_WEEKLY,
} from '../../helper/constants';

import './barchart.scss';

import noDataImage from './../../Assets/no-data.svg'; // Update the path to your image

const CustomTooltip = ({ body, active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
        <h4>{`${body.labelX}: ${payload[0].payload.xAxis}`}</h4>
        <p>{`${body.labelY}: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const BarChartComponent = ({ data, body, title, loading = false, onRangeChanged, currentRange }) => {
  const [aspectRatio, setAspectRatio] = useState(2); // Default aspect ratio
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseMove = (state) => {
    if (state && state.activeTooltipIndex !== undefined) {
      setActiveIndex(state.activeTooltipIndex);
    }
  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset the active index when mouse leaves
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 600) {
        setIsSmallDevice(true);
        setAspectRatio(1); // For mobile screens
      } else if (width >= 600 && width < 1200) {
        setIsSmallDevice(true);
        setAspectRatio(3); // For tablets
      } else {
        setIsSmallDevice(false);
        setAspectRatio(3.2); // For larger screens
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderContent = () => {
    if (!data || data.length === 0) {
      return (
        <div className="no-data">
          <img src={noDataImage} alt="No data" />
          <div>
            <p>Not enough data yet</p>
          </div>
        </div>
      );
    }

    return (
      <div className="barGraph">
        <ResponsiveContainer width="100%" aspect={aspectRatio}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <XAxis dataKey="xAxis" stroke="#8884d8">
              <Label value={body.labelX} offset={-5} position="insideBottom" />
            </XAxis>
            <YAxis stroke="#8884d8">
              <Label
                value={body.labelY}
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip cursor={false} content={<CustomTooltip body={body} />} />
            <Bar dataKey="yAxis" label={{ fontSize: 12, fill: '#FFFFFF' }} radius={[10, 10, 0, 0]}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === activeIndex ? '#381495' : '#67519F'} // Change bar color on hover/tap
                  style={{
                    filter: index === activeIndex ? 'none' : 'none', // No shadow applied
                    boxShadow: index === activeIndex ? '0px 4px 10px rgba(255, 115, 0, 0.5)' : 'none', // Custom shadow color when active
                  }}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <>
      <h2>{title} Performance</h2>
      <div className="performance">
        <div className="subtabs-container2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Dropdown for range selection only on mobile screens */}
          <div
            style={{
              display: isSmallDevice ? 'flex' : 'none',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <FormControl
              variant="standard"
              style={{
                marginLeft: 'auto',
              }}
              sx={{ marginBottom: '16px' }}
            >
              <Select
                labelId="range-select-label"
                value={currentRange}
                onChange={(e) => onRangeChanged(e.target.value)}
              >
                <MenuItem value={RANGE_WEEKLY}>Weekly</MenuItem>
                <MenuItem value={RANGE_MONTHLY}>Monthly</MenuItem>
                <MenuItem value={RANGE_NIGHENTY_DAYS}>90 Days</MenuItem>
                <MenuItem value={RANGE_ALL_TIME}>All Time</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* Buttons for range selection on larger screens */}
          <div className="desktop-only">
            <div style={{ display: !isSmallDevice ? 'flex' : 'none', justifyContent: 'center', gap: '10px' }}>
              <button
                className={currentRange === RANGE_WEEKLY ? 'active-subtab' : ''}
                onClick={() => onRangeChanged(RANGE_WEEKLY)}
                style={{ cursor: 'pointer' }}
              >
                Weekly
              </button>
              <button
                className={currentRange === RANGE_MONTHLY ? 'active-subtab' : ''}
                onClick={() => onRangeChanged(RANGE_MONTHLY)}
                style={{ cursor: 'pointer' }}
              >
                Monthly
              </button>
              <button
                className={currentRange === RANGE_NIGHENTY_DAYS ? 'active-subtab' : ''}
                onClick={() => onRangeChanged(RANGE_NIGHENTY_DAYS)}
                style={{ cursor: 'pointer' }}
              >
                90 Days
              </button>
              <button
                className={currentRange === RANGE_ALL_TIME ? 'active-subtab' : ''}
                onClick={() => onRangeChanged(RANGE_ALL_TIME)}
                style={{ cursor: 'pointer' }}
              >
                All Time
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </div>
    </>
  );
};

export default BarChartComponent;

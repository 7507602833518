// dataStore.js
class LocalDataStore {
    constructor() {
        this.data = {};
    }

    setItem(key, value) {
        console.log('setItem',key,value);
        this.data[key] = value;
        console.log('setItem','data',this.data);

    }

    getItem(key) {
        console.log('getItem',key);
        var d= this.data[key];
        console.log('getItem','data',d);

        return d;
    }

    clear() {
        this.data = {};
    }
}

export const localDataStore = new LocalDataStore();

// Spinner.js
import React from 'react';
import './Spinner.scss'; // Optional CSS for styling

const Spinner = () => {
    return (
        <div className="spinner-overlay">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default Spinner;


export const apiSecret1 = process.env.REACT_APP_API_SECRET_1;
export const apiSecret2 = process.env.REACT_APP_API_SECRET_2;
export const apiSecureHeader = process.env.REACT_APP_API_SECURE_HEADER;
export const s3BucketUrl = "https://hallelujah.today/proxy?token=" + btoa(localStorage.getItem('token')) + '&path=';

export const RANGE_WEEKLY = 7;
export const RANGE_MONTHLY = 30;
export const RANGE_NIGHENTY_DAYS = 90;
export const RANGE_ALL_TIME = -1;


export const getS3BucketUrl = (path) => {
    return "https://hallelujah.today/proxy?token=" + btoa(localStorage.getItem('token')) + '&path=' + path;

}
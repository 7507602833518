import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyOTP } from '../../helper/helper';
import toast from 'react-hot-toast';
import {
    Button,
    Typography,
    TextField,
    Box,
    CircularProgress,
    Divider,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import OtpInput from '../../components/widget/OtpInput';
import { createSession } from '../../middleware/auth';

// MUI Styled Components for Custom Styling
const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
}));

const TimerText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const VerifyEmailOtp = ({ email, duration = 180, onResend, onChangeEmail, loading }) => {
    const [durationText, setDurationText] = useState('');
    const [otp, setOtp] = useState('');
    const [verifying, setVerifying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [restartTimer, setRestartTimer] = useState(false); // State to trigger timer restart
    const navigate = useNavigate();
    const [timerZero, setTimerZero] = useState(false);

    useEffect(() => {
        let timer = duration;
        setTimerZero(false);
        setErrorMessage('');
        const interval = setInterval(() => {
            const minutes = Math.floor(timer / 60).toString().padStart(2, '0');
            const seconds = (timer % 60).toString().padStart(2, '0');
            setDurationText(`${minutes}:${seconds}`);
            if (--timer < 0) {
        setTimerZero(true);
        setErrorMessage('Timeout');
        clearInterval(interval);
                setDurationText('');
            }
        }, 1000);

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, [duration, restartTimer]); // Include restartTimer in dependencies

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        setVerifying(true);
        try {
            const response = await verifyOTP({ email, otp });            
            createSession(response.data.token,JSON.stringify(response.data.user));

            setErrorMessage('');
            toast.success('OTP Verified successfully');
            navigate('/'); // Navigate to home page
        } catch (error) {
            setErrorMessage(error.error || 'An error occurred');
            toast.error(error.error || 'An error occurred');
        }
        setVerifying(false);
    };

    const handleResendOTP = async () => {
        await onResend();
        setRestartTimer(prev => !prev); // Toggle restartTimer state to trigger useEffect
    };

    return (
        <StyledBox>
            <Typography variant="h4" component="h2" gutterBottom>
                OTP Verification
            </Typography>
            <Typography variant="body1" gutterBottom>
                OTP sent to {email}
            </Typography>
            <form onSubmit={handleVerifyOTP} style={{ width: '100%' }}>
                <Box sx={{ mb: 2 }}>
                    <OtpInput
                        otp={otp.split('')} // Pass otp array to OtpInput
                        onChange={(value) => setOtp(value)}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {!loading && (
                        <Button 
                            variant="text" 
                            onClick={onChangeEmail} 
                            sx={{ textAlign: 'center' }}
                        >
                            Change Email
                        </Button>
                    )}
                    <Button 
                        variant="text" 
                        onClick={handleResendOTP} 
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : <RefreshIcon />}
                    >
                        Resend OTP
                    </Button>
                    {!loading && <TimerText variant="body2">{durationText}</TimerText>}
                </Box>
                {!loading && !timerZero && (
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        
                        fullWidth
                        disabled={verifying||(otp.length!=6)}
                    >
                        {verifying ? <CircularProgress size={24} /> : 'Verify OTP'}
                    </Button>
                )}
                {errorMessage && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                )}
            </form>
        </StyledBox>
    );
};

export default VerifyEmailOtp;

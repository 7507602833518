import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from 'recharts';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './linegraph.scss';
import noDataImage from './../../Assets/no-data.svg'; // Update the path to your image
import { RANGE_ALL_TIME, RANGE_MONTHLY, RANGE_NIGHENTY_DAYS, RANGE_WEEKLY } from '../../helper/constants';

// Custom tooltip component
const CustomTooltip = ({ body, active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
        <h4>{`${body.labelX}: ${payload[0].payload.xAxis}`}</h4>
        <p>{`${body.labelY}: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const AreaChartComponent = ({ data, body, title, loading = false, onRangeChanged, currentRange }) => {
  // State for dynamic aspect ratio
  const [aspectRatio, setAspectRatio] = useState(2); // Default aspect ratio
  const [isSmallDevice, setIsSmallDevice] = useState(false); // Default aspect ratio
  useEffect(() => {
    const handleResize = () => {
      // Calculate aspect ratio based on window width
      const width = window.innerWidth;

      if (width < 600) {
        setIsSmallDevice(true);
        setAspectRatio(1); // For mobile screens
      } else if (width >= 600 && width < 1200) {
        setIsSmallDevice(true);
        setAspectRatio(3); // For tablets
      } else {
        setIsSmallDevice(false);
        setAspectRatio(3); // For larger screens
      }
    };

    // Set initial aspect ratio on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Cleanup function to remove the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this runs once on mount

  // Conditional rendering based on data availability
  const renderContent = () => {
    if (!data || data.length === 0) {
      return (
        <div className="no-data">
          <img src={noDataImage} alt="No data" />
          <div>
            <p>Not enough data yet</p>
          </div>
        </div>
      );
    }

    return (
      <div className="barGraph">
        <ResponsiveContainer width="100%">
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 20, bottom: 5 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            {/* XAxis with label */}
            <XAxis dataKey="xAxis" stroke="#8884d8">
              <Label value={body.labelX} offset={-5} position="insideBottom" />
            </XAxis>
            {/* YAxis with label */}
            <YAxis stroke="#8884d8">
              <Label value={body.labelY} angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
            </YAxis>
            <CartesianGrid stroke="#f5f5f5" strokeDasharray="" />
            <Tooltip content={<CustomTooltip body={body} />} />
            <Area
              type="monotone"
              dataKey="yAxis"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <>
      <h2>{title} Performance</h2>
      <div className="performance">
      <div className="subtabs-container2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Dropdown for range selection only on mobile screens */}
          <div
          
          style={{
            display:isSmallDevice?'flex':'none',
            justifyContent:'flex-start',
            width:'100%'
          }}>
          <FormControl variant='standard' style={{
            marginLeft:'auto'
          }}  sx={{  marginBottom: '16px' }}>
            <Select
              labelId="range-select-label"
              value={currentRange}
              onChange={(e) => onRangeChanged(e.target.value)}
            >
              <MenuItem value={RANGE_WEEKLY}>Weekly</MenuItem>
              <MenuItem value={RANGE_MONTHLY}>Monthly</MenuItem>
              <MenuItem value={RANGE_NIGHENTY_DAYS}>90 Days</MenuItem>
              <MenuItem value={RANGE_ALL_TIME}>All Time</MenuItem>
            </Select>
          </FormControl>
          </div>
          {/* Buttons for range selection on larger screens */}
          <div className='desktop-only'>
          <div 
           style={{ 
            display:!isSmallDevice?'flex':'none',
            
            
            justifyContent: 'center', gap: '10px' }}>
            <button
              className={currentRange === RANGE_WEEKLY ? 'active-subtab' : ''}
              onClick={() => onRangeChanged(RANGE_WEEKLY)}
              style={{ cursor: 'pointer' }}
            > Weekly</button>
            <button
              className={currentRange === RANGE_MONTHLY ? 'active-subtab' : ''}
              onClick={() => onRangeChanged(RANGE_MONTHLY)}
              style={{ cursor: 'pointer' }}
            > Monthly</button>
            <button
              className={currentRange === RANGE_NIGHENTY_DAYS ? 'active-subtab' : ''}
              onClick={() => onRangeChanged(RANGE_NIGHENTY_DAYS)}
              style={{ cursor: 'pointer' }}
            > 90 Days</button>
            <button
              className={currentRange === RANGE_ALL_TIME ? 'active-subtab' : ''}
              onClick={() => onRangeChanged(RANGE_ALL_TIME)}
              style={{ cursor: 'pointer' }}
            > All Time</button>
          </div>
          </div>
        </div>
        {renderContent()}
      </div>
    </>
  );
};

export default AreaChartComponent;

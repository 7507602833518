import Sidebar from "../../components/sidebar/Sidebar";
import Widget from "../../components/widget/Widget";
import BarChartComponent from "../../components/barChart/BarChart";
import PieChartComponent from "../../components/barChart/PieChart";
import AreaChartComponent from "../../components/barChart/linegraph";
import { CircularProgress, Grid } from "@mui/material";

import {
  getOverviewSummary,
  getOverviewPerformance,
} from "../../helper/apiHelper";
import "./Home.scss";
import LoadingSpinner from "../../components/widget/LoadingSpinner";
import { ShimmerText } from "react-shimmer-effects";
import { RANGE_WEEKLY } from "../../helper/constants";
import { useEffect, useState } from "react";
import { localDataStore } from "../../store/LocalDataStore";
import CenteredCircularProgress from "../../components/CenteredCircularProgress";

const Overview = () => {
  const [rangeChanged, setRangeChanged] = useState(0);
  const [rangeMusic, setRangeMusic] = useState(RANGE_WEEKLY);
  const [rangePodcasts, setRangePodcasts] = useState(RANGE_WEEKLY);
  const [rangeAll, setRangeAll] = useState(RANGE_WEEKLY);
  const [loadingOverviewSummary, setLoadingOverviewSummary] = useState(false);
  const [loadingOverviewPerformance, setLoadingOverviewPerformance] =
    useState(false);
  useState(false);
  const [activeSubTab, setActiveSubTab] = useState("all");
  const [summaryData, setSummaryData] = useState(null);
  const [performanceData, setPerformanceData] = useState({
    music: [],
    podcasts: [],
  });
  const [audPerfData, setAudPerfData] = useState({ music: [], podcasts: [] });

  const onRangeChanged = (range, type) => {
    setRangeChanged(rangeChanged + 1);
    if (type == "all") {
      setRangeAll(range);
    } else if (type == "music") {
      setRangeMusic(range);
    } else {
      setRangePodcasts(range);
    }
  };
  const getRange = (type) => {
    if (type == "all") {
      return rangeAll;
    } else if (type == "music") {
      return rangeMusic;
    } else {
      return rangePodcasts;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchSummaryData(activeSubTab);
      await fetchPerformanceData(activeSubTab);
    };

    fetchData();
  }, [activeSubTab]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchPerformanceData(activeSubTab);
    };

    fetchData();
  }, [activeSubTab, rangeChanged]);

  const handleSubTabChange = (subTab) => {
    setActiveSubTab(subTab);
  };

  const fetchSummaryData = async (type) => {
    var old=localDataStore.getItem(type+'summaryData');
    if(old!=null){
      setSummaryData(old);
      return;
    }
    setLoadingOverviewSummary(true);
    try {
      const sumData = await getOverviewSummary(type);
      localDataStore.setItem(type+'summaryData',sumData)
      setSummaryData(sumData);
    } catch (error) {
      // console.error('Failed to fetch summary data:', error);
    }
    setLoadingOverviewSummary(false);
  };

  const fetchPerformanceData = async (type) => {
    var old=localDataStore.getItem(type+'fetchPerformanceData'+getRange(type));
    if(old!=null){
      setPerformanceData(old);
      return;
    }
    console.log("fetchPerformanceData", type);

    setLoadingOverviewPerformance(true);
    try {
      const perfData = await getOverviewPerformance(type, getRange(type));
      localDataStore.setItem(type+'fetchPerformanceData'+getRange(type),perfData);
      setPerformanceData(perfData);
    } catch (error) {
      // console.error('Failed to fetch performance data:', error);
    }
    setLoadingOverviewPerformance(false);
  };

  const xsSize = 8;
  const smSize = 4;
  const mdSize = 3;
  const spacingSize = 1;
  
  const widgetData = [
    { title: "Likes", countKey: "likes", link: "View likes" },
    { title: "Plays", countKey: "plays", link: "View plays" },
    { title: "Plays Per Song", countKey: "playPerSong", link: "View plays per song" },
    { title: "Audience", countKey: "audience", link: "View audience" }
  ];
  
  const renderSummaryWidgets = (summary = {}, loading = false) => (
    <Grid container spacing={spacingSize}>
      {widgetData.map((widget, index) => (
        <Grid key={index} item xs={xsSize} sm={smSize} md={mdSize}>
          <Widget
            loading={loading}
            title={widget.title}
            count={summary[widget.countKey] ?? 0}
            link={widget.link}
          />
        </Grid>
      ))}
    </Grid>
  );
  
  

  return (
    <div>
      <div className="subtabs-container">
        <button
          className={activeSubTab === "all" ? "active-subtab" : ""}
          onClick={() => handleSubTabChange("all")}
          style={{ cursor: "pointer" }}
        >
          All
        </button>
        <button
          className={activeSubTab === "music" ? "active-subtab" : ""}
          onClick={() => handleSubTabChange("music")}
          style={{ cursor: "pointer" }}
        >
          Music
        </button>
        <button
          className={activeSubTab === "podcasts" ? "active-subtab" : ""}
          onClick={() => handleSubTabChange("podcasts")}
          style={{ cursor: "pointer" }}
        >
          Podcast
        </button>
      </div>
      { (
        <>
          <div className="MusicOverview">
            <h2>
              {activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)}{" "}
              Summary
            </h2>
            <div className="widgets">
              {renderSummaryWidgets(
                summaryData?.summary,
                loadingOverviewSummary
              )}
            </div>
          </div>
         
         
        </>
      )}
       { performanceData && (
        <>
          
          
          <div>
            <BarChartComponent
              currentRange={getRange(activeSubTab)}
              onRangeChanged={(range) => onRangeChanged(range, activeSubTab)}
              loading={loadingOverviewPerformance}
              data={performanceData?.data}
              body={performanceData}
              title={
                activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)
              }
            />
          </div>
        </>
      )}
      {
        !performanceData&&<CenteredCircularProgress/>
      }
    </div>
  );
};

export default Overview;

import { Field, ErrorMessage } from 'formik';
import { FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import RequiredMark from '../../components/widget/RequiredMark';

const GenderSelection = ({ values, handleChange,className }) => (
  <FormControl className={className} component="fieldset" sx={{ mb: 2 }}>
    <Typography variant="body1" sx={{ }}>
      Select Gender <RequiredMark/>
    </Typography>
    <RadioGroup
      name="gender"
      style={{
        padding:'8px'
      }}
      value={values.gender}  
      onChange={handleChange} 
      row
    >
      <FormControlLabel
        value="male"
        control={<Radio />}
        label="Male"
      />
      <FormControlLabel
        value="female"
        control={<Radio />}
        label="Female"
      />
    </RadioGroup>
    <ErrorMessage name="gender" component="div" className="error-message" />
  </FormControl>
);

export default GenderSelection;

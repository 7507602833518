// SpinnerContext.js
import React, { createContext, useContext, useState } from 'react';
import Spinner from '../components/Spinner';

const SpinnerContext = createContext();

export const useSpinner = () => {
    return useContext(SpinnerContext);
};

export const SpinnerProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);

    const showSpinner = () => setIsVisible(true);
    const closeSpinner = () => setIsVisible(false);

    return (
        <SpinnerContext.Provider value={{ showSpinner, closeSpinner }}>
            {children}
            {isVisible && <Spinner />}
        </SpinnerContext.Provider>
    );
};

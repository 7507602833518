import Sidebar from "../../components/sidebar/Sidebar";
import Widget from "../../components/widget/Widget";
import BarChartComponent from "../../components/barChart/BarChart";
import PieChartComponent from "../../components/barChart/PieChart";
import AreaChartComponent from "../../components/barChart/linegraph";
import {
  getOverviewSummary,
  getOverviewPerformance,
  getAudienceSummary,
  getAudiencePerformance,
} from "../../helper/apiHelper";
import "./Home.scss";
import LoadingSpinner from "../../components/widget/LoadingSpinner";
import { ShimmerText } from "react-shimmer-effects";
import { RANGE_WEEKLY } from "../../helper/constants";
import { useEffect, useState } from "react";
import Overview from "./Overview";
import Audience from "./Audience";

const Home = () => {
  const [activeTab, setActiveTab] = useState("overview");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container">
      <Sidebar />

      <main className="content">
        <div className="homeContainer">
          <h1>Analytics</h1>
          <div className="analyticsContainer">
            <div className="tab-container">
              <button
                className={activeTab === "overview" ? "active-tab" : ""}
                onClick={() => handleTabChange("overview")}
                style={{ cursor: "pointer" }}
              >
                Overview
              </button>
              <button
                className={activeTab === "audience" ? "active-tab" : ""}
                onClick={() => handleTabChange("audience")}
                style={{ cursor: "pointer" }}
              >
                Audience
              </button>
            </div>

            <div>
              {activeTab === "overview" && <Overview />}

              {activeTab === "audience" && <Audience />}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;

import { Box, Typography, Button, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import importEpisodeIcon from "../../Assets/import-episode.png";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { apiSecureHeader } from "../../helper/constants";
import { getSettings } from "../../helper/apiHelper";

const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px", 
  maxWidth: "200px",
}));

const UploadComponent = ({onUploaded}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);

  const [maxAudioFileSizeLimit, setMaxAudioFileSizeLimit] = useState(20);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    // Check for supported formats (mp3, m4a, wav)
    const validAudioTypes = ["audio/mpeg", "audio/x-m4a", "audio/wav"];
    
    if (file && validAudioTypes.includes(file.type)) {
      setSelectedFile(file);
      handleImport(file);
    } else {
      toast.error("Unsupported file format. Please upload mp3, m4a, or wav files.");
      setSelectedFile(null); // Reset file if unsupported
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();
        if (settings) {
          setMaxAudioFileSizeLimit(settings['max_audio_file_size_limit']);
        }
      } catch (error) {
        toast.error(error.error);
      }
    };

    fetchSettings();
  }, []); // Empty dependency array means it runs once on mount


  const handleImport = async (file) => {
    if (!file) return;

    const fileName = file.name;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", fileName);

    try {
      const response = await axios.post(
        `https://hallelujah.today/api/godcast/segments/add`,
        formData,
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(progress); // Set progress state
          },
        }
      );

      const savedItem = response.data;
      onUploaded(savedItem);
      toast.success("Uploaded successfully");
    } catch (error) {
      toast.error("Failed adding to Library");
      setUploadProgress(0); // Reset progress on error
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
      <Box
        component="img"
        src={importEpisodeIcon}
        alt="upload icon"
        sx={{
          width: 144,
          height: 144,
        }}
      />
      <Typography variant="h5" fontWeight="bold" mt={2}>
        Upload
      </Typography>

      <Typography variant="body2" fontWeight={500} mt={1}>
        Upload finished episode from single file.
      </Typography>

      <Typography variant="body2" fontWeight={500} mt={1}>
        Supported formats are mp3, m4a, and wav.
      </Typography>

      <Typography variant="body2" component="span" mt={1}>
  Read Our{" "}
  <a
    href="https://hallelujah.today/platform-guidelines"
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'underline', color: 'inherit' }}
  >
    Platform Rules
  </a>{" "}
  and{" "}
  <a
    href="https://hallelujah.today/godcast/terms"
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'underline', color: 'inherit' }}
  >
    Terms Of Use
  </a>
  .
</Typography>


      <RoundedButton variant="contained" color="primary" sx={{ mt: 3 }} onClick={() => fileInputRef.current.click()}>
        Select A Audio File (100 KB to {maxAudioFileSizeLimit}MB)
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the file input element
          accept="audio/mpeg, audio/mp4, audio/wav" // Restrict to mp3, m4a, and wav
          onChange={handleFileChange}
        />
      </RoundedButton>

      {selectedFile && (
        <Box width="100%" mt={3}>
          <Typography variant="body2" fontWeight={500} mt={1}>
            Uploading: {selectedFile.name}
          </Typography>

          <LinearProgress variant="determinate" value={uploadProgress} sx={{ mt: 2 }} />
          <Typography variant="body2" mt={1}>
            {uploadProgress}% uploaded
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadComponent;

import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

/** Generate Common Headers */
const getHeaders = () => ({
    secure: apiSecureHeader,
    is_admin: true,
    Accept: 'application/json',
    'Content-Type':'application/json',
});

/** To get email from Token */
export async function getEmail() {
    const token = localStorage.getItem('token');
    if (!token) return Promise.reject("Cannot find Token");
    let decode = jwtDecode(token);
    return decode;
}

/** Authenticate function */
export async function authenticate(email) {
    try {
        const response = await axios.post('https://hallelujah.today/api/v1/auth/godcast/email/check', { 
            email,
            secret1: apiSecret1,
            secret2: apiSecret2
        }, { headers: getHeaders() });
        return response;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
        return Promise.reject({ error: errorMessage });
    }
}

/** Register user function */
export async function registerUser(credentials) {
    const updatedCredentials = {
        ...credentials,
        secret1: apiSecret1,
        secret2: apiSecret2,
    };
    try {
        const { data: { msg } } = await axios.post(
            'https://hallelujah.today/api/v1/auth/app/godcast/signup', 
            updatedCredentials, 
            { headers: getHeaders() }
        );        
        return Promise.resolve(msg);
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
        return Promise.reject({ error: errorMessage });
    }
}

/** Login function */
export async function verifyPassword({ email, password }) {
    try {
        if (email && password) {
            const { data } = await axios.post(
                'https://hallelujah.today/api/v1/auth/app/signin',
                { email, password, secret1: apiSecret1, secret2: apiSecret2, otpType: "email" },
                { headers: getHeaders() }
            );
            return Promise.resolve({ data });
        }
        return Promise.reject({ error: "Please enter email and password and try again." });
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Password doesn't Match...!";
        return Promise.reject({ error: errorMessage });
    }
}

/** Verify OTP */
export async function verifyOTP({ email, otp }) {
    try {
        const { data, status } = await axios.post(
            'https://hallelujah.today/api/v1/auth/app/godcast/otp/verify',
            { email, otp, secret1: apiSecret1, secret2: apiSecret2, otpType: 'email', idToken: null },
            { headers: getHeaders() }
        );
        return { data, status };
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
        return Promise.reject({ error: errorMessage });
    }
}

/** Send OTP for forgot password */
export async function forgotPasswordSentOtp({ email }) {
    try {
        if (email) {
            const { data } = await axios.post(
                'https://hallelujah.today/api/v1/auth/app/forgot/password',
                { email, secret1: apiSecret1, secret2: apiSecret2 },
                { headers: getHeaders() }
            );
            return Promise.resolve({ data });
        }
        return Promise.reject({ error: "Please enter email and try again." });
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
        return Promise.reject({ error: errorMessage });
    }
}

/** Reset password */
export async function resetPassword({ email, password, otp }) {
    try {
        if (email && password && otp) {
            const { data } = await axios.post(
                'https://hallelujah.today/api/v1/auth/app/reset/password',
                { email, password, otp, secret1: apiSecret1, secret2: apiSecret2 },
                { headers: getHeaders() }
            );
            return Promise.resolve({ data });
        }
        return Promise.reject({ error: "Please enter email, otp and new password and try again." });
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
        return Promise.reject({ error: errorMessage });
    }
}

/** Resend OTP */
export async function resendOTP(email) {
    try {
        const resendOTPBody = {
            ...email,
            secret1: apiSecret1,
            secret2: apiSecret2,
        };
        const { data: { msg } } = await axios.post(
            'https://hallelujah.today/api/v1/auth/app/godcast/otp/resend', 
            resendOTPBody, 
            { headers: getHeaders() }
        );   
        return Promise.resolve(msg);
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
        return Promise.reject({ error: errorMessage });
    }
}

export const formatDateForMySQL = (date) => {
    const now = new Date(date);
  
    // Get the date and time parts
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero
    const day = now.getDate().toString().padStart(2, "0"); // Add leading zero
    const hours = now.getHours().toString().padStart(2, "0"); // 24-hour format
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Add leading zero
    const seconds = now.getSeconds().toString().padStart(2, "0"); // Add leading zero
  
    // Return formatted date and time as 'YYYY-MM-DD HH:MM:SS'
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import BackButton from "../../components/buttons/BackButton";
import FilledButton from "../../components/buttons/FilledButton";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import "./Profile.scss";
import axios from "axios";
import document from "../../Assets/document-upload.svg";
import trash from "../../Assets/trash.svg";
import RoundedButton from "../../components/buttons/RoundedButton";
import RequiredMark from "../../components/widget/RequiredMark";
import { getS3BucketUrl } from "../../helper/constants";
import { formPublish, filePublish } from "../../helper/userHelper";
import { useAuthStore } from "../../store/store";
import { FormControl } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import GenderSelection from "./GenderSelection";

const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [imageFileName, setImageFileName] = useState("");
  // const [formData, setFormData] = useState(new FormData());
  const userArrayString = localStorage.getItem("user");
  const { auth, logout, setUser } = useAuthStore(); // Destructure auth, logout, setUser from the store

  var user;
  var avatar;
  var Img;
  if (userArrayString != null) {
    user = JSON.parse(userArrayString);
    avatar = (user.image_url)??getS3BucketUrl(user.avatar);
  }

  const initialValues = {
    f_name: user?.f_name,
    l_name: user?.l_name,
    date_of_birth: user?.date_of_birth,
    mobile: user?.mobile,
    dail_code: user?.dail_code,
    email: user?.email,
    house_no: user?.house_no,
    block_name: user?.block_name,
    pincode: user?.pincode,
    country: user?.country,
    city: user?.city,
    state: user?.state,
    gender: user?.gender,
    band: user?.band,
    avatar: user?.avatar,
    avatar_f: null,
  };

  const validationSchema = Yup.object({
    f_name: Yup.string().required("First Name is required"),
    l_name: Yup.string().required("Last Name is required"),
    date_of_birth: Yup.date()
      .required("Date of Birth is required")
      .test(
        "is-18",
        "You must be at least 18 years old",
        (value) => moment().diff(moment(value), "years") >= 18
      ),
    mobile: Yup.string().required("Phone Number is required"),
    // dail_code: Yup.string().required('Country Code is required'),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // avatar_f: Yup.string().required('Profile photo is required'),

    house_no: Yup.string().required("House No is required"),
    block_name: Yup.string().required("Block Name is required"),
    pincode: Yup.string().required("Pincode is required"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    band: Yup.string().required("Band is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);

    const formData = new FormData();
    formData.append("f_name", values.f_name);
    formData.append("l_name", values.l_name);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("mobile", values.mobile);
    formData.append("dail_code", values.dail_code);
    formData.append("email", values.email);
    formData.append("house_no", values.house_no);
    formData.append("block_name", values.block_name);
    formData.append("pincode", values.pincode);
    formData.append("country", values.country);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("band", values.band);
    formData.append("gender", values.gender);

    try {
      let avatar = values.avatar_f || values.avatar; // Simplified avatar handling

      if (!avatar) {
        toast.error("Profile photo is required");
        setLoading(false);
        setSubmitting(false);
        return; // Return early, but make sure to reset states first
      }

      formData.append("avatar", avatar); // Append the avatar to formData

      console.log("formData", formData.get("avatar"));

      // Proceed to submit the form
      const response = await formPublish(formData);

      // If the response is successful, update the user data
      const updatedUser = {
        ...user,
        ...values,
        avatar: response.avatar, // Use response's avatar
      };

      localStorage.setItem("user", JSON.stringify(response)); // Store updated user data

      setImageFileName(response.avatar); // Update image filename
      avatar = (response.image_url)??getS3BucketUrl(response.avatar); // Generate S3 URL for avatar
      setUser(response);
      toast.success("Profile updated successfully");
    } catch (error) {
      // Handle errors more generically
      toast.error(error.error || "Profile update failed");
    }

    setLoading(false);
    setSubmitting(false);
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div
          style={{
            display: "flex",
            textAlign: "start",
            alignItems: "start",
            alignContent: "start",
          }}
        >
          <BackButton onClick={() => navigate(-1)} />
          <h1 className="px-3">Profile</h1>
        </div>
        <div className="form-content">
          <Toaster />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isValid, dirty }) => (
              <Form>
                <div className="file">
                  <div className="upload-file-group">
                    <p>Your Photo</p>

                    <label className="file-label">
                      {(values.avatar || values.avatar_f) && (
                        <div>
                          {values.avatar_f && (
                            <img
                              src={URL.createObjectURL(values.avatar_f)}
                              alt="Avatar Preview"
                              className="image"
                            />
                          )}
                          {values.avatar && (
                            <img
                              src={avatar}
                              alt="Avatar Prev"
                              className="image"
                            />
                          )}

                          <img
                            src={trash}
                            alt="Delete Icon"
                            className="icon"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue("avatar_f", null);
                              setFieldValue("avatar", null);

                              setImageFileName(null);
                            }}
                          />
                        </div>
                      )}
                      {!values.avatar && !values.avatar_f && (
                        <>
                          <img
                            src={document}
                            className="upload-icon"
                            alt="Upload Icon"
                          />
                          {""}
                          Please upload a photo in square format for the best
                          appearance (Max 500 KB).
                          <input
                            name="avatar"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) => {
                              if (event.currentTarget.files[0]) {
                                setFieldValue(
                                  "avatar_f",
                                  event.currentTarget.files[0]
                                );
                                setImageFileName(
                                  event.currentTarget.files[0]?.name || ""
                                );
                              }
                            }}
                          />
                        </>
                      )}
                    </label>

                    <ErrorMessage
                      name="avatar"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* <div
                  
                  >
                    <h2 style={{ marginBottom: "10px" }}>Your Photo</h2>
                    <label
                      className="file-label"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {(values.avatar || values.avatar_f) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {values.avatar_f && (
                            <img
                              src={URL.createObjectURL(values.avatar_f)}
                              alt="Avatar Preview"
                              className="image"
                              style={{
                                width: "160px",
                                height: "160px",
                                borderRadius: "50%",
                                marginBottom: "10px",
                                border: "2px solid #67519F",
                              }}
                            />
                          )}
                          {values.avatar && (
                            <img
                              src={avatar}
                              alt="Avatar Prev"
                              className="image"
                              style={{
                                width: "160px",
                                height: "160px",
                                borderRadius: "50%",
                                marginBottom: "10px",
                                border: "2px solid #67519F",
                              }}
                            />
                          )}
                          <img
                            src={trash}
                            alt="Delete Icon"
                            className="icon"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue("avatar_f", null);
                              setFieldValue("avatar", null);
                              setImageFileName(null);
                            }}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </div>
                      )}
                      {!values.avatar && !values.avatar_f && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src={document}
                            className="upload-icon"
                            alt="Upload Icon"
                            style={{ marginRight: "10px" }}
                          />
                          Upload
                          <input
                            name="avatar"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) => {
                              if (event.currentTarget.files[0]) {
                                setFieldValue(
                                  "avatar_f",
                                  event.currentTarget.files[0]
                                );
                                setImageFileName(
                                  event.currentTarget.files[0]?.name || ""
                                );
                              }
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                      )}
                    </label>

                    <ErrorMessage
                      name="avatar"
                      component="div"
                      className="error-message"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    />
                  </div> */}
                </div>
                <div className="basicinfo">
                  <div className="form-group">
                    <label>
                      First Name <RequiredMark />
                    </label>
                    <Field name="f_name" type="text" />
                    <ErrorMessage
                      name="f_name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Last Name <RequiredMark />
                    </label>
                    <Field name="l_name" type="text" />
                    <ErrorMessage
                      name="l_name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Date of Birth <RequiredMark />
                    </label>
                    <Field name="date_of_birth" type="date" />
                    <ErrorMessage
                      name="date_of_birth"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* <div className="radio-group">
                    <label>
                      Gender <RequiredMark />
                    </label>
                    <div role="group" aria-labelledby="my-radio-group">
                      <Field name="gender" type="radio" value="male" />
                      Male
                      <Field name="gender" type="radio" value="female" />
                      Female
                    </div>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="error-message"
                    />
                  </div> */}
                  <GenderSelection
                  className="form-group"
                    handleChange={(e) =>
                      setFieldValue("gender", e.target.value)
                    }
                    values={values}
                  />
                  <div className="form-group">
                    <label>
                      Email <RequiredMark />
                    </label>
                    <Field name="email" type="email" disabled />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>
                      Mobile <RequiredMark />
                    </label>
                    <Field name="mobile" type="mobile" />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="error-message"
                    />
                  </div> */}

                  <div className="form-group">
                    <label>
                      House No <RequiredMark />
                    </label>
                    <Field name="house_no" type="text" />
                    <ErrorMessage
                      name="house_no"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Block Name <RequiredMark />
                    </label>
                    <Field name="block_name" type="text" />
                    <ErrorMessage
                      name="block_name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Pincode <RequiredMark />
                    </label>
                    <Field name="pincode" type="text" />
                    <ErrorMessage
                      name="pincode"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Country <RequiredMark />
                    </label>
                    <Field name="country" type="text" />
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      City <RequiredMark />
                    </label>
                    <Field name="city" type="text" />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      State <RequiredMark />
                    </label>
                    <Field name="state" type="text" />
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      Band <RequiredMark />
                    </label>
                    <Field name="band" type="text" />
                    <ErrorMessage
                      name="band"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="buttons">
                  <div className="button-group">
                    {!loading && (
                      <RoundedButton
                        type="button"
                        text="Cancel"
                        onClick={() => navigate(-1)}
                      />
                    )}
                    <RoundedButton
                      type="submit"
                      text={isValid && dirty ? "Save" : "No changes done"}
                      loading={loading}
                      disabled={!isValid || !dirty || loading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;

import React from 'react';
import { Box, Typography } from '@mui/material';

const ReviewStatusCard = ({ reviewStatus, reviewMessage }) => {
  return (
    <Box
      sx={{
        p: 2,
        border: '0.1px solid',
        borderColor: 'red',
        borderRadius: 2,
        bgcolor: 'background.paper',
        boxShadow: 0.1,
        mt: 2,
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography
          variant="body1"
          sx={{ color: 'primary.main', fontWeight: 'bold', pr: 1 }}
        >
          {reviewStatus}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >
          {reviewMessage}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReviewStatusCard;

import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const Widget = ({ title, count, link, loading = false }) => {
  if (loading) {
    return (
      <Box
        sx={{
          padding: 2,
          border: '1px solid #D8DFE0',
          borderRadius: 2,
          height: 142,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
        margin:1,
        border: '1px solid #D8DFE0',
        borderRadius: 2,
        height: 'auto', // Allow height to adjust based on content
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        width: '100%', // Take full width of the parent
        maxWidth: 200, // Maximum width for the widget
        gap: 1,
      }}
    >
      <Typography variant="h6" color="#626D6F">
        {title}
      </Typography>
      <Typography variant="h4" color="#333839">
        {count}
      </Typography>
      <Typography variant="body2" color="#67519F">
        {link}
      </Typography>
    </Box>
  );
};

export default Widget;

import PieChartComponent from "../../components/barChart/PieChart";
import AreaChartComponent from "../../components/barChart/linegraph";
import {
  getAudienceSummary,
  getAudiencePerformance,
} from "../../helper/apiHelper";
import "./Home.scss";
import { ShimmerText } from "react-shimmer-effects";
import { RANGE_WEEKLY } from "../../helper/constants";
import { useEffect, useState } from "react";
import { localDataStore } from "../../store/LocalDataStore";
import CenteredCircularProgress from "../../components/CenteredCircularProgress";

const Audience = () => {
  const [rangeChanged, setRangeChanged] = useState(0);
  const [rangeMusic, setRangeMusic] = useState(RANGE_WEEKLY);
  const [rangePodcasts, setRangePodcasts] = useState(RANGE_WEEKLY);
  const [rangeAll, setRangeAll] = useState(RANGE_WEEKLY);
  const [loadingAudienceSummary, setLoadingAudienceSummary] = useState(false);
  useState(false);
  const [loadingAudiencePerformance, setLoadingAudiencePerformance] =
    useState(false);
  const [activeSubTab, setActiveSubTab] = useState("all");

  const [audienceData, setAudienceData] = useState(null);
  const [audPerfData, setAudPerfData] = useState({ music: [], podcasts: [] });

  const onRangeChanged = (range, type) => {
    setRangeChanged(rangeChanged + 1);
    if (type == "all") {
      setRangeAll(range);
    } else if (type == "music") {
      setRangeMusic(range);
    } else {
      setRangePodcasts(range);
    }
  };
  const getRange = (type) => {
    if (type == "all") {
      return rangeAll;
    } else if (type == "music") {
      return rangeMusic;
    } else {
      return rangePodcasts;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAudienceData(activeSubTab);
      await fetchAudiencePerformanceData(activeSubTab);
    };

    fetchData();
  }, [activeSubTab]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAudiencePerformanceData(activeSubTab);
    };

    fetchData();
  }, [activeSubTab, rangeChanged]);

  const handleSubTabChange = (subTab) => {
    setActiveSubTab(subTab);
  };

  const fetchAudienceData = async (type) => {
    var old = localDataStore.getItem(type + "fetchAudienceData");
    if (old != null) {
      setAudienceData(old);
      return;
    }
    setLoadingAudienceSummary(true);
    try {
      const audData = await getAudienceSummary(type);
      localDataStore.setItem(type + "fetchAudienceData", audData);
      setAudienceData(audData);
    } catch (error) {
      // console.error('Failed to fetch audience data:', error);
    }
    setLoadingAudienceSummary(false);
  };

  const fetchAudiencePerformanceData = async (type) => {
    var old = localDataStore.getItem(
      type + "fetchAudiencePerformanceData" + getRange(type)
    );
    if (old != null) {
      setAudPerfData(old);
      return;
    }
    setLoadingAudiencePerformance(true);
    try {
      const audPerfData = await getAudiencePerformance(type, getRange(type));
      localDataStore.setItem(
        type + "fetchAudiencePerformanceData" + getRange(type),
        audPerfData
      );
      setAudPerfData(audPerfData);
    } catch (error) {
      // console.error('Failed to fetch audience performance data:', error);
    }
    setLoadingAudiencePerformance(false);
  };

  const renderAudienceContent = (activeSubTab) => {
    return (
      <>
        { (
          <>
            <div className="geoLoc">
              <h2>
                {activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)}{" "}
                Geographic Location
              </h2>
              {audienceData?.countryWise?.length > 0 ? (
                <div className="country-data-container">
                  {audienceData?.countryWise.map((country, index) => (
                    <div className="countryInfo" key={index}>
                      <p className="countryName">{country.name || "Unknown"}</p>
                      <p className="audPercent">{`${country.audience_percentage.toFixed(
                        1
                      )}%`}</p>
                    </div>
                  ))}
                </div>
              ) : loadingAudienceSummary ? (
                <div className="country-data-container">
                  <ShimmerText line={5} gap={10} />
                </div>
              ) : (
                <p>No country data available.</p>
              )}
            </div>
          </>
        )}

        {audPerfData && (
          <div className="audience-performance">
            
            
            <AreaChartComponent
              currentRange={getRange(activeSubTab)}
              onRangeChanged={(range) => onRangeChanged(range, activeSubTab)}
              data={audPerfData?.data || []}
              body={audPerfData}
              title={`${
                activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)
              } Audience`}
            />
          </div>
        )}

        { (
          <div className="agender">
            <div className="age">
              {audienceData?.ageWise?.length > 0 ? (
                <PieChartComponent data={audienceData?.ageWise} title="Age" />
              ) : (
                <p>No age data available.</p>
              )}
            </div>
            <div className="age">
              {audienceData?.genderWise?.length > 0 ? (
                <PieChartComponent
                  data={audienceData?.genderWise}
                  title="Gender"
                />
              ) : (
                <p>No gender data available.</p>
              )}
            </div>
          </div>
        )}

     

      </>
    );
  };

  return (
    <div>
      <div className="subtabs-container">
        <button
          className={activeSubTab === "all" ? "active-subtab" : ""}
          onClick={() => handleSubTabChange("all")}
          style={{ cursor: "pointer" }}
        >
          All
        </button>
        <button
          className={activeSubTab === "music" ? "active-subtab" : ""}
          onClick={() => handleSubTabChange("music")}
          style={{ cursor: "pointer" }}
        >
          Music
        </button>
        <button
          className={activeSubTab === "podcasts" ? "active-subtab" : ""}
          onClick={() => handleSubTabChange("podcasts")}
          style={{ cursor: "pointer" }}
        >
          Podcast
        </button>
      </div>
      {renderAudienceContent(activeSubTab)}
    </div>
  );
};

export default Audience;

// MobileTopBar.js
import React from 'react';
import './MobileTopBar.scss';
import Hamburger from 'hamburger-react';

const MobileTopBar = ({
  isSidebarOpen,
  setSidebarOpen,
  name,
  avatar,
  logo,
  openImageModal,
}) => {
  return (
    <div className="mobile-top-bar">
      {/* Hamburger Menu */}
      <Hamburger toggled={isSidebarOpen} toggle={setSidebarOpen} />

      {/* Logo and User Information */}
      {/* <div className="logo-and-profile" onClick={openImageModal}>
        <img src={logo} alt="Godcast Logo" className="logo" />
        <div className="profile-info">
          <span className="user-name">{name}</span>
          
        </div>
      </div> */}
    </div>
  );
};

export default MobileTopBar;

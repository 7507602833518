import React, { useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import '../home/Home.scss';
import profile from '../../Assets/profile.svg';
import terms from '../../Assets/terms.svg';
import guidelines from '../../Assets/guidelines.svg';
import privacy from '../../Assets/privacy.svg';
import support from '../../Assets/support.svg';
import SettingsItem from "./SettingsItem";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/buttons/BackButton";
import './Settings.scss';
import { Button } from '@mui/material';
import arrowRight from '../../Assets/arrow-right.svg';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import Modal from 'react-modal';
import checked from '../../Assets/checked.png';
import CircularProgress from '@mui/material/CircularProgress';
import { apiSecureHeader } from '../../helper/constants';

Modal.setAppElement('#root');

const SettingsPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [deletionReason, setDeletionReason] = useState('');
    const [confirmationText, setConfirmationText] = useState('');
    const requiredConfirmationText = "Delete My Account";

    const openConfirmation = () => {
        setConfirmationIsOpen(true);
    };

    const closeConfirmation = () => {
        setConfirmationIsOpen(false);
        setCurrentStep(1);
        setConfirmationText(''); // Reset confirmation text
    };

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleAccountDeletion = async () => {
        setLoading(true);
    
        try {
            // Making a GET request and passing the query parameter in the URL
            const response = await axios.get(
                'https://hallelujah.today/api/users/account/request/delete', 
                {
                    params: { reason: deletionReason },  // Pass the reason as query param
                   
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                }
            );
    
            // Checking the response status
            if (response.status === 200) {
                toast.success('Account deletion request submitted successfully.');
                localStorage.removeItem('token');
                navigate('/auth');
            } else {
                toast.error('Failed to submit account deletion request.');
            }
        } catch (error) {
            // Handling and displaying the actual error message from the server
            if (error.response) {
                // Server responded with a status other than 2xx
                toast.error(`Error: ${error.response.data.message || 'Failed to submit account deletion request.'}`);
            } else if (error.request) {
                // Request was made, but no response was received
                toast.error('No response received from the server.');
            } else {
                // Something else happened in setting up the request
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            setLoading(false);
            closeConfirmation();
        }
    };
    

    return (
        <div className="container">
            <Sidebar />
            <Toaster />
            <main className="content">
                <div style={{ width: '100%' }}>
                    <div className="heading" style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '5px' }}>
                        <BackButton />
                        <h1>Settings</h1>
                    </div>
                    <div style={{ padding: '60px' }}>
                        <SettingsItem name="Profile" icon={profile} onClick={() => navigate('/profile')} />
                        <SettingsItem name="Terms and Conditions" icon={terms} onClick={() => window.open('https://hallelujah.today/godcast/terms', '_blank')} />
                        <SettingsItem name="Platform Guidelines" icon={guidelines} onClick={() => window.open('https://hallelujah.today/platform-guidelines', '_blank')} />
                        <SettingsItem name="Privacy Policy" icon={privacy} onClick={() => window.open('https://hallelujah.today/godcast/privacy', '_blank')} />
                        <SettingsItem name="Support" icon={support} onClick={() => navigate('/support')} />
                    </div>
                    <div className="footer">
                        <Button
                            variant="contained"
                            onClick={openConfirmation}
                            style={{
                                backgroundColor: '#ff4d4d',
                                color: 'white',
                                borderRadius: '30px',
                                padding: '12px 30px',
                                fontSize: '1rem',
                                margin: '0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            Delete Account
                            <img src={arrowRight} alt="Arrow Right" height="24px" style={{ paddingLeft: '8px' }} />
                        </Button>
                    </div>
                </div>
            </main>

            {/* Modal for multi-step confirmation */}
            <Modal
                isOpen={confirmationIsOpen}
                onRequestClose={closeConfirmation}
                contentLabel="Confirm Delete Account"
                style={{
                    content: {
                        width: '500px',
                        height: '400px',
                        margin: 'auto',
                        borderRadius: '20px',
                        padding: '30px',
                        backgroundColor: '#f7f7f7',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                {currentStep === 1 && (
                    <>
                        <h2 style={{ color: '#333', marginBottom: '10px' }}>Reason for Leaving</h2>
                        <p style={{ textAlign: 'center', fontSize: '0.9rem', color: '#555' }}>
                            Why are you leaving Godcast? (Optional)
                        </p>
                        <textarea
                            value={deletionReason}
                            onChange={(e) => setDeletionReason(e.target.value)}
                            placeholder="Enter your reason here..."
                            style={{
                                width: '100%',
                                height: '80px',
                                padding: '10px',
                                marginTop: '15px',
                                borderRadius: '8px',
                                borderColor: '#ccc',
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleNextStep}
                            style={{
                                marginTop: '20px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                borderRadius: '30px',
                                padding: '10px 20px',
                            }}
                        >
                            Next
                        </Button>
                    </>
                )}
                {currentStep === 2 && (
                    
                   loading?<>
                    <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh' // Or any height you prefer
        }}>
           <CircularProgress />
        </div>
                   
                   </>: <>
                   <h2 style={{ color: '#d9534f', marginBottom: '10px' }}>Confirm Account Deletion</h2>
                   <p style={{ textAlign: 'center', fontSize: '0.9rem', color: '#555' }}>
                       To confirm, please type "<strong>{requiredConfirmationText}</strong>" exactly below.
                   </p>
                   <input
                       type="text"
                       value={confirmationText}
                       onChange={(e) => setConfirmationText(e.target.value)}
                       placeholder={`Type "${requiredConfirmationText}"`}
                       style={{
                           width: '100%',
                           padding: '10px',
                           marginTop: '15px',
                           borderRadius: '8px',
                           borderColor: '#ccc',
                       }}
                   />
                   <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                       <Button
                           onClick={handlePreviousStep}
                           style={{
                               backgroundColor: '#ddd',
                               borderRadius: '30px',
                               padding: '10px 20px',
                               marginRight: '10px',
                           }}
                       >
                           Back
                       </Button>
                       
                       
                       <Button
                           variant="contained"
                           
                           onClick={handleAccountDeletion}
                           style={{
                               backgroundColor: '#d9534f',
                               color: 'white',
                               borderRadius: '30px',
                               padding: '10px 20px',
                           }}
                           disabled={confirmationText !== requiredConfirmationText}
                       >
                           Yes, Delete
                       </Button>
                   </div>
               </> 
                   
                )}
                {currentStep === 3 && (
                    <>
                        <img src={checked} alt="Success" style={{ width: '60px', marginBottom: '20px' }} />
                        <h2 style={{ color: '#4CAF50', marginBottom: '10px' }}>Success</h2>
                        <p style={{ textAlign: 'center', fontSize: '0.9rem', color: '#555' }}>
                            Your account has been scheduled for deletion.
                        </p>
                        <Button
                            variant="contained"
                            onClick={closeConfirmation}
                            style={{
                                marginTop: '20px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                borderRadius: '30px',
                                padding: '10px 20px',
                            }}
                        >
                            Close
                        </Button>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default SettingsPage;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  fetchPublishedMusic,
  reviewMusic,
  unpublishMusic,
  deleteMusic,
  archiveMusic,
} from "../../helper/musicHelper";
import "./PublishedMusic.scss";
import { Toaster, toast } from "react-hot-toast";
import musicImg from "../../Assets/musicnote.svg";
import PublishedMusicItem from "./PublishedMusicItem";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import checked from "../../Assets/checked.png";
import { useSpinner } from "../../helper/SpinnerContext";

Modal.setAppElement("#root");

const PublishedMusic = () => {
  const [musicList, setMusicList] = useState([]);
  const [unpublishedMusicList, setUnpublishedMusicList] = useState([]);
  const [archivedMusicList, setArchivedMusicList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("published");
  const [selectedMusic, setSelectedMusic] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState("");
  const open = Boolean(anchorEl);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const closeConfirmation = () => {
    setSelectedMusic(null);
    setConfirmationIsOpen(false);
  };
  const { showSpinner, closeSpinner } = useSpinner();

  const handlePublish = async () => {
    if (!selectedMusic) return;

    showSpinner();
    try {
      var res = await reviewMusic(selectedMusic.id); // Simulate API call to publish music

      if (res.active == 1) {
        // Update the selected music's active status to 1
        const updatedMusic = { ...selectedMusic, active: 1 };

        setUnpublishedMusicList((prev) =>
          prev.filter((music) => music.id !== selectedMusic.id)
        );

        setMusicList((prev) => [...prev, updatedMusic]);

        toast.success("music Published Successfully");
      }else{
        toast.error(res.review_message);

      }
    } catch (error) {
      toast.error(error.error);
    } finally {
      handleClose();
    }
    closeSpinner();
  };

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(10); // Items per page

  const navigate = useNavigate();

  useEffect(() => {
    const loadPublishedMusic = async (page) => {
      try {
        const musicData = await fetchPublishedMusic(page, perPage);
        setMusicList(musicData.data.filter((music) => music.active === 1));
        setUnpublishedMusicList(
          musicData.data.filter((music) => music.active === 0)
        );
        setArchivedMusicList(
          musicData.data.filter((music) => music.active === -1)
        );

        setTotalPages(musicData.last_page); // Set total pages from API response
      } catch (error) {
        toast.error(error.error);
      } finally {
        setLoading(false);
      }
    };

    loadPublishedMusic(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5; // Set the maximum number of visible page buttons
    const halfVisible = Math.floor(maxVisiblePages / 2); // Calculate half the max visible pages

    // Calculate start and end page numbers
    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, currentPage + halfVisible);

    // Adjust if near the start
    if (currentPage <= halfVisible) {
      endPage = Math.min(maxVisiblePages, totalPages);
    }

    // Adjust if near the end
    if (currentPage + halfVisible >= totalPages) {
      startPage = Math.max(1, totalPages - maxVisiblePages + 1);
    }

    // Add "Previous" button
    if (currentPage > 1) {
      pages.push(
        <button key="prev" onClick={() => handlePageChange(currentPage - 1)}>
          &laquo; Previous
        </button>
      );
    }

    // Add "1" page button
    if (startPage > 1) {
      pages.push(
        <button key={1} onClick={() => handlePageChange(1)}>
          1
        </button>
      );
      // Add ellipsis if there are pages skipped
      if (startPage > 2) {
        pages.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Add page number buttons within the calculated range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={currentPage === i ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < totalPages) {
      // Add ellipsis if there are pages skipped
      if (endPage < totalPages - 1) {
        pages.push(<span key="ellipsis-end">...</span>);
      }
      pages.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      );
    }

    // Add "Next" button
    if (currentPage < totalPages) {
      pages.push(
        <button key="next" onClick={() => handlePageChange(currentPage + 1)}>
          Next &raquo;
        </button>
      );
    }

    return <div className="pagination">{pages}</div>;
  };

  const getList = () => {
    let list = [];
    switch (activeTab) {
      case "published":
        list = musicList;
        break;
      case "unpublished":
        list = unpublishedMusicList;
        break;
      case "archived":
        list = archivedMusicList;
        break;
      default:
        list = musicList;
        break;
    }
    return list;
  };
  const handlePlayPause = (musicId, audioRef) => {
    if (
      currentlyPlaying &&
      currentlyPlaying.audioRef &&
      currentlyPlaying.id !== musicId
    ) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };
  const handleUnpublish = async () => {
    if (!selectedMusic) return;

    showSpinner();
    try {
      await unpublishMusic(selectedMusic.id); // Simulate API call to publish music

      // Update the selected music's active status to -1 (archived)
      const updatedMusic = { ...selectedMusic, active: 0 };

      if (activeTab === "published") {
        setMusicList((prev) =>
          prev.filter((music) => music.id !== selectedMusic.id)
        );
      } else if (activeTab === "archived") {
        setArchivedMusicList((prev) =>
          prev.filter((music) => music.id !== selectedMusic.id)
        );
      }

      setUnpublishedMusicList((prev) => [...prev, updatedMusic]);
      toast.success("Music Unpublished Succesfully");
    } catch (error) {
      toast.error(error.error);
    } finally {
      handleClose();
    }
    closeSpinner();
  };

  // console.log(selectedMusic);
  const handleDelete = async () => {
    if (!selectedMusic) return;

    showSpinner();
    try {
      // console.log(selectedMusic.id);
      await deleteMusic(selectedMusic.id); // API call to delete music

      // Remove the deleted music from the appropriate list
      if (selectedMusic.active === 1) {
        setMusicList((prevList) =>
          prevList.filter((music) => music.id !== selectedMusic.id)
        );
      } else if (selectedMusic.active === 0) {
        setUnpublishedMusicList((prevList) =>
          prevList.filter((music) => music.id !== selectedMusic.id)
        );
      } else {
        setArchivedMusicList((prevList) =>
          prevList.filter((music) => music.id !== selectedMusic.id)
        );
      }

      toast.success("Music deleted successfully");
    } catch (error) {
      // console.error('Error deleting music:', error);
      toast.error(error.error);
    } finally {
      closeConfirmation();
    }
    closeSpinner();
  };
  const handleArchive = async () => {
    if (!selectedMusic) return;

    try {
      await archiveMusic(selectedMusic.id);

      // Update the selected music's active status to -1 (archived)
      const updatedMusic = { ...selectedMusic, active: -1 };

      if (selectedMusic.active === 0) {
        setUnpublishedMusicList((prevList) =>
          prevList.filter((music) => music.id !== selectedMusic.id)
        );
      }

      setArchivedMusicList((prev) => [...prev, updatedMusic]);

      toast.success("Music archived successfully");
    } catch (error) {
      toast.error(error.error);
    } finally {
      closeConfirmation();
    }
  };

  const handleEdit = () => {
    const serializableMusic = {
      id: selectedMusic.id,
      title: selectedMusic.title || "Unknown",
      writers: selectedMusic.writers,
      artists_names: selectedMusic.artists_names,
      genre: selectedMusic.category_id,
      language: selectedMusic.language,
      image: selectedMusic.image,
      audio_file: selectedMusic.audio_file,
    };
    navigate("/publish-music", { state: { selectedMusic: serializableMusic } });
  };

  const handleClick = (event, music) => {
    setAnchorEl(event.currentTarget);
    setSelectedMusic(music);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedMusic(null);
  };
  const openConfirmation = (music) => {
    handleClose();
    setConfirmationIsOpen(true);
  };
  const renderMusicList = () => {
    let list = getList();

    if (loading) {
      return <div>Loading...</div>;
    }

    if (list.length > 0) {
      return (
        <div className="musicList">
          {list.map((music) => (
            <PublishedMusicItem
              key={music.id}
              music={music}
              handleClick={handleClick}
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              handleUnpublish={handleUnpublish}
              handlePublish={handlePublish}
              handleDelete={() => openConfirmation(music)}
              handleArchive={() => openConfirmation(music)}
              handleEdit={handleEdit}
              isPublished={activeTab === "published"}
              onPlayPause={(audioRef) => handlePlayPause(music.id, audioRef)}
              currentlyPlaying={currentlyPlaying?.id === music.id}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="emptyState">
          <img src={musicImg} alt="No music" />
          <p>Add music to see here</p>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div className="app-container">
          <Toaster />
          <h1>My Assets (Music)</h1>
          <div className="tabs">
            <button
              className={activeTab === "published" ? "active" : ""}
              onClick={() => setActiveTab("published")}
            >
              Published
            </button>
            <button
              className={activeTab === "unpublished" ? "active" : ""}
              onClick={() => setActiveTab("unpublished")}
            >
              Unpublished
            </button>
            <button
              className={activeTab === "archived" ? "active" : ""}
              onClick={() => setActiveTab("archived")}
            >
              Archived
            </button>
          </div>
          {renderMusicList()}
          <footer className="pagination-footer">
            {!loading && renderPagination()}
          </footer>{" "}
        </div>
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: "190px",
              borderRadius: "12px",
            },
          }}
        >
          <img
            src={checked}
            alt=""
            style={{
              width: "48px",
              height: "48px",
              marginBottom: "-35px",
            }}
          />
          <h2>Confirmation</h2>
          <p
            style={{
              textAlign: "center",
              marginTop: "-5px",
              fontSize: "0.85em",
            }}
          >
            {selectedMusic && selectedMusic.active === 0
              ? "Are you sure you want to archive this music?"
              : "Are you sure you want to delete this music?"}
          </p>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeConfirmation}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={
                selectedMusic && selectedMusic.active === 0
                  ? handleArchive
                  : handleDelete
              }
            >
              Yes
            </button>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default PublishedMusic;

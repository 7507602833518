import axios from 'axios';
import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';
export const getAuthHeaders = () => ({
  'secure': apiSecureHeader,
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
  'is_admin': true,
});

export const splitSegment = async (rawData, onUploadProgress, onSuccess, onError) => {
  try {
    // formData.append('secret1', apiSecret1);
    // formData.append('secret2', apiSecret2);

    const response = await axios.post(
      'https://hallelujah.today/api/segment/split',
      rawData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader,
          'is_admin': true,
          'Content-Type': 'application/json',
          // Note: Axios automatically sets 'Content-Type': 'multipart/form-data' for FormData
        },
        onUploadProgress: (progressEvent) => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        }
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    } else {
      console.error('Error splitting segment:', error);
    }
    throw new Error('Failed to split segment');
  }
};
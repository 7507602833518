import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import Sidebar from "../../components/sidebar/Sidebar";
import RecordItem from './RecordItem';
import RoundedButton from "../../components/buttons/RoundedButton";
import { useNavigate, useLocation } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { apiSecureHeader } from '../../helper/constants';
import { AddToSegments } from '../../helper/musicHelper';
import BackButton from "../../components/buttons/BackButton";

const BackgroundMusic = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [backgroundMusics, setBackgroundMusics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [selectedMusic, setSelectedMusic] = useState(null);
  const open = Boolean(anchorEl);

  const segmentId = location.state?.selectedSegmentId;
  // console.log(segmentId);

  const { onBackgroundSelected } = location.state || {};

  const getAuthHeaders = () => ({
    'secure': apiSecureHeader,
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'is_admin': true,
  });

  useEffect(() => {
    const fetchBackgroundMusics = async () => {
      try {
        const response = await axios.get('https://hallelujah.today/api/godcast/podcasts/content', {
          headers: getAuthHeaders(),
          params: { 'version':'2.0' },
        });
        setBackgroundMusics(response.data.background_musics);
      } catch (error) {
        toast.error('Failed to fetch background musics');
      } finally {
        setLoading(false);
      }
    };

    fetchBackgroundMusics();
  }, []);

  useEffect(() => {
    // Stop currently playing music when switching tabs
    if (currentlyPlaying) {
      currentlyPlaying.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    }
  }, []);

  const handlePlayPause = (musicId, audioRef) => {
    if (currentlyPlaying && currentlyPlaying.audioRef && currentlyPlaying.id !== musicId) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };

  const handleClick = (event, audio) => {
    setAnchorEl(event.currentTarget);
    setSelectedMusic(audio);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(selectedMusic);

  const handleAdd = async () => {
    if (!selectedMusic) return;
  
    try {
        const { data } = await axios.post('https://hallelujah.today/api/segments/bg/add-process', {
            id: segmentId,
            background_id: selectedMusic.id
        }, {
            headers: { 
            'secure': apiSecureHeader, 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'is_admin': true,
          }
        });
        // console.log(data);
        toast.success('Background music added to segment');
        navigate('/create-episode'); // Navigate back to CreateEpisode after successful addition
    } catch (error) {
        toast.error('Failed to add background music');
    }
    
  };

  return (
    <div className='container'>
      <Sidebar />
      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <div className="heading">
            <BackButton onClick={() => navigate(-1)} />
            <p style={{fontSize: '40px'}}>Select Background Sound</p>
          </div>
          <div className="episode-list">
            {loading ? (
              <Typography variant="body1" color="textSecondary">
                Loading content...
              </Typography>
            ) : backgroundMusics.length > 0 ? (
              <div className="recordList library" style={{maxHeight:'580px'}}>
              {backgroundMusics.map((categoryObj, index) => (
                  <div key={index}>
                    <h3 style={{marginLeft: '20px'}}>{categoryObj.category}</h3>
                    {categoryObj.items.map((record) => (
                      <RecordItem
                        key={record.id}
                        record={record}
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        open={open}
                        isBackground={true}
                        handleClose={handleClose}
                        handleAdd={handleAdd}
                        onPlayPause={(audioRef) => handlePlayPause(record.id, audioRef)}
                        currentlyPlaying={currentlyPlaying?.id === record.id}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <Typography variant="body1" color="textSecondary">
                No background musics found
              </Typography>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default BackgroundMusic;

import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from 'https://cdn.jsdelivr.net/npm/wavesurfer.js@7/dist/wavesurfer.esm.js';
import Sidebar from "../../components/sidebar/Sidebar";
import './EditAudio.scss';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchSegmentById } from '../../helper/musicHelper';
import TrimmableSlider from '../../trimmer/TrimmableSlider';
import { splitSegment } from '../../helper/splitHelper';
import RoundedButton from '../../components/buttons/RoundedButton';
import { toast } from 'react-hot-toast';
import FilledButton from '../../components/buttons/FilledButton';
import { getS3BucketUrl } from '../../helper/constants';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#D8DFE0',
  progressColor: 'blue',
  cursorColor: 'transparent',
  responsive: true,
  height: 210,
  normalize: true,
  backend: 'MediaElement',
  barWidth: 5,
  barGap: 4,
});

const EditAudio = () => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const location = useLocation();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [positions, setPositions] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioFileName, setAudioFileName] = useState('');
  const [loading, setLoading] = useState(true);
  const audioFileId = location.state?.audioFileId;

  // console.log(audioFileId);



  function formatTime(seconds) {
    let date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  useEffect(() => {
    const loadAudioData = async () => {
      try {
        const segmentData = await fetchSegmentById(audioFileId);
        // console.log(segmentData);
        const audioFile = ((segmentData.audio_file_url))??getS3BucketUrl(segmentData.original_file);

        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);

        wavesurfer.current.load(audioFile);

        wavesurfer.current.on('ready', () => {
          setDuration(wavesurfer.current.getDuration());
          setAudioFileName(segmentData.name);
          setLoading(false);
        });

        wavesurfer.current.on('audioprocess', () => {
          setCurrentTime(wavesurfer.current.getCurrentTime());
        });
      } catch (error) {
        // console.error('Failed to fetch segment data:', error);
        toast.error(error.error);
      }
    };

    loadAudioData();

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.un('audioprocess');
        wavesurfer.current.un('ready');
        wavesurfer.current.destroy();
      }
    };
  }, [audioFileId]);

  const handlePlayPause = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();
  };
  const saveTrimBars = (mPositions) => {
    const flooredPositions = mPositions.map(pos => Math.floor(pos));
    setPositions(flooredPositions);
  };
  const navigate = useNavigate();
  const logFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      // console.log(`${key}: ${value}`);
    }
  };
  const handleSave = async () => {
    setLoading(true);
    try {
      // Prepare the raw data in the desired format
      const rawData = {
        id: audioFileId, // The ID of the audio file
        totalDuration: Math.floor(duration), // Floor the total duration
        positions: positions.map(Math.floor) // Floor each position
      };

      // Log the object to check the structure
      // console.log(rawData);
  
      const response = await splitSegment(rawData);
      if (response) {
        navigate(-1);
      }
    } catch (error) {
      // console.error('Error during save:', error);
      toast.error(error.response?.data?.message || 'Failed to save segment.');
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleSlide = (pos) => {
    // console.log(pos);
    // setPlaying(!playing);
    // wavesurfer.current.playPause();
    // console.log(`Slider Position: ${pos.toFixed(2)} seconds`);
    wavesurfer.current.seekTo(pos / wavesurfer.current.getDuration());
    // setPlaying(!playing);
    // wavesurfer.current.playPause();
  };

  // console.log(duration);

  return (
    <div className="edit-audio-container">
      <Sidebar />
      <div className="edit-audio-content">
        <h1>Edit Audio</h1>
        <div className="waveform-container">
          <div id="waveform" ref={waveformRef}></div>
          <TrimmableSlider duration={duration} onSlide={handleSlide} saveTrimBars={saveTrimBars} />
        </div>
        <div className="controls">
          <button onClick={handlePlayPause}>
            {playing ? <PauseCircleFilledIcon style={{ fontSize: '5rem' }} className='musicStatus' /> : <PlayCircleFilledIcon className='musicStatus' style={{ fontSize: '5rem' }} />}
          </button>
        </div>
        <div className="audio-info">
          <span>
            Playing: {audioFileName} <br />
          </span>
          <span>
            Duration: {formatTime(duration)} | Current Time: {formatTime(currentTime)}
            <br />
          </span>
        </div>
        <div style={{
          padding: "1em",
          margin: "1em auto",
          width: "300px"
        }}>
          <FilledButton onClick={handleSave} text="Save" loading={loading}></FilledButton>
        </div>
      </div>
    </div>
  );
};

export default EditAudio;
